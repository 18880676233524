<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Chloroform,
        <chemical-latex content="CHCl3," />
        is a common solvent in organic chemistry. A solution is prepared by dissolving
        <number-value :value="mass1" unit="\text{g}" />
        of naphthalene,
        <chemical-latex content="C10H8" />
        in
        <number-value :value="mass2" unit="\text{g}" />
        of
        <stemble-latex content="$\text{CHCl}_3.$" />
        Please answer the following questions regarding the solution.
      </p>

      <p class="mb-3">a) What is the molality of the naphthalene solution?</p>

      <calculation-input
        v-model="inputs.molality"
        class="mb-5"
        prepend-text="$\text{Molality:}$"
        append-text="$m$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) What is the boiling point of this solution? Assume that naphthalene is non-volatile
        (which isn't entirely true).
      </p>

      <calculation-input
        v-model="inputs.bpSoln"
        prepend-text="$\text{T}_\text{b}:$"
        append-text="$^\circ\text{C}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question417',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molality: null,
        bpSoln: null,
      },
    };
  },
  computed: {
    mass1() {
      return this.taskState.getValueBySymbol('mass1').content;
    },
    mass2() {
      return this.taskState.getValueBySymbol('mass2').content;
    },
  },
};
</script>
